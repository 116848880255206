<template>
  <div>
    <div :class="['square-image', cover ? 'image-cover' : 'image-contain']"
         v-if="previewData && !keepOriginalSize"
         :style="{ 'background-image': 'url(' + previewData + ')' }"
    ><slot /></div>
    <img :src="previewData" v-else-if="previewData && keepOriginalSize" />
    <div v-else class="square-image lighter">
      <icon v-if="!forbidden" class="lighter-lighter preview-icon" size="2" type="image" />
      <icon v-if="forbidden" class="lighter-lighter preview-icon" size="2" type="ban" />
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
export default {
  name: "VideoPreview",
  components: {
    Icon,
  },
  props: {
    /*
    * @previewData String can be URL or base46-data
    * **/
    previewData: {type: String, default: ''},
    keepOriginalSize: {type: Boolean, default: false},
    forbidden: {type: Boolean, default: false},
    cover: {type: Boolean, default: false}
  },
}
</script>

<style lang="scss" scoped>
.image-cover {
  background-size: cover;
}
</style>